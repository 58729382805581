/* styles.css or styles.scss */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Sour+Gummy:ital,wght@0,100..900;1,100..900&display=swap');

* {
  scroll-behavior: smooth;
}

body,
html {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px !important;
  padding: 0px !important;
}

textarea,
input {
  box-shadow: 0px 0px 0px transparent !important;
}

button:focus-visible,
button:focus {
  box-shadow: 0px 0px 0px transparent !important;
}

body {
  background-color: rgba(128, 128, 128, 0.342);
}

:root {
  --xsm: '12px !important';
  --sm: '14px !important';
  --md: '16px !important';
  --intermediate: '18px !important';
  --lg: '24px !important';
  --xl: '42px !important';
  --xxl: '52px !important';
  --bigger: '64px !important';

  // Font Family
  --ff-sour: "Sour Gummy", sans-serif;
  --ff-lato: "Lato", serif;
}

.ff-sour {
  font-family: var(--ff-sour) !important;
}

.ff-lato {
  font-family: var(--ff-lato) !important;
}

// header
// .navbar-nav .nav-link {
//   position: relative;

//   &:after {
//     content: "";
//     position: absolute;
//     left: 0px;
//     border-bottom: 1px solid transparent;
//     height: 100%;
//     width: 100%;
//     transform: translateY(20px);

//     &:hover {
//       border-color: white;
//       transform: translateY(0px);
//     }
//   }
// }
.dropdown {
  .dropdown-toggle::after {
    margin-left: 0.7em;
  }

  .dropdown-item {
    transition: all .3s;
  }

  .dropdown-item:hover,
  .dropdown-item:active {
    color: white !important;
    background-color: #212529 !important;
  }
}

// header

// responsive
button.navbar-toggler {
  border: none !important;
  padding: 0px !important;

  &:focus {
    box-shadow: 0px 0px 0px transparent !important;

  }

  .navbar-toggler-icon {
    width: 28px !important;
    height: 28px !important;
    background-image: url(../../assets/image/crossicon.svg) !important;
  }

  &.collapsed {
    .navbar-toggler-icon {
      background-image: url(../../assets/image/menuicon.svg) !important;
    }
  }
}

.site-logo {
  max-width: 10vh !important;
}

// header

// homepage
.light-overlay {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.hero-slider {

  .carousel-control-prev,
  .carousel-control-next,
  .carousel-indicators {
    display: none;
  }

  &.carousel.slide {

    .carousel-item {
      img {
        height: 700px !important;
      }


    }
  }
}

.feature-card {
  border-radius: 0 !important;
  border: none !important;

  img {
    border-radius: 0 !important;
    height: 32vh;
    max-height: 278px;
  }

  .card-text {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .feature-btn {
    border: 1px solid #212529;
    text-decoration: none !important;
    transition: all .3s;

    &:hover {
      background-color: #212529;
      color: white !important;
    }
  }
}

// aboutus
.about-tabs {
  .nav-tabs {
    border: 2px solid #212529 !important;
    padding: 4px;
    width: 100%;
    max-width: max-content;
    overflow-x: auto !important;
    white-space: nowrap !important;
    flex-wrap: nowrap !important;
    scroll-snap-type: x mandatory;


    &::-webkit-scrollbar {
      display: block !important;
      width: 8px !important;
      height: 8px !important;
      background-color: #212529 !important;
    }

    &::-webkit-scrollbar-thumb {
      background: #858585 !important;
    }

    .nav-item {
      display: inline-block !important;
      flex: unset !important;
      min-width: max-content !important;
      scroll-snap-align: center;

      &>.nav-link {
        border: 2px solid #212529 !important;
        width: max-content;
        border-radius: 0px !important;
        color: #212529 !important;
        transition: all .3s;

        &.active {
          background-color: #212529 !important;
          color: white !important;
        }
      }
    }
  }
}
.home-tabs{
  .nav-tabs {
    border: none !important;
  }
}
ul.custom-list.list-group {
  .list-group-item {
    position: relative;

    &::before {
      content: "";
      width: 6px;
      height: 6px;
      display: inline-block;
      background-color: #212529;
      // border-radius: 50%;
      margin: 0px 6px 3px;
    }
  }
}
.aboutImg1{
  height:550px;
}
.aboutImg2{
  height:380px;
}

// media page
.media-main-section {
  border-radius: 0 !important;
  border: none !important;

  img {
    border-radius: 0 !important;
    height: 290px;
  }

  .card-text {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .feature-btn {
    border: 1px solid #212529;
    text-decoration: none !important;
    transition: all .3s;

    &:hover {
      background-color: #212529;
      color: white !important;
    }
  }
}

// -- Sermons page
.sermons-audio {
  audio {
    background-color: #f1f3f4 !important;
  }

  .share-btn {
    border: 1px solid #212529;
    border-radius: 0px !important;
    text-decoration: none !important;
    transition: all .3s;
    background-color: transparent;
    color: #212529;

    &:hover {
      background-color: #212529 !important;
      color: white !important;
    }

    &::after {
      display: none !important;
    }
  }
}

// -- songs
.media-songs-audio {
  audio {
    background-color: #f1f3f4 !important;
  }


  .share-btn {
    border: 1px solid #212529;
    border-radius: 0px !important;
    text-decoration: none !important;
    transition: all .3s;
    background-color: transparent;
    color: #212529;

    &:hover {
      background-color: #212529 !important;
      color: white !important;
    }

    &::after {
      display: none !important;
    }
  }

}

// -- videos
.church-videos {
  .card {
    border-radius: 0px !important;

    .card-body {
      iframe {
        min-height: 300px !important;
        height: 40vh !important;
      }
    }
  }
}

// --- light box css for image preview in picture galleries.
.lightbox-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -12px !important;
  row-gap: 24px;

  img {
    box-shadow: 0px 0px 0px transparent !important;
    padding: 0px 12px !important;
    margin: 0px !important;
    width: 16.66666667% !important;
    height: 100% !important;
  }
}

// media page

// global dark btn
.dark-btn {
  border: 1px solid #212529 !important;
  border-radius: 0px !important;
  text-decoration: none !important;
  transition: all .3s !important;
  background-color: transparent !important;
  color: #212529 !important;

  &:hover {
    background-color: #212529 !important;
    color: white !important;
  }

  &::after {
    display: none !important;
  }
}

// footer
footer {
  .action-btn {
    transition: all .3s;

    &:hover {
      background-color: white;
      color: #212529 !important;
    }
  }
}

@media (max-width:1025px) {
  .hero-slider.carousel.slide .carousel-item img {
    height: 600px !important;
  }

  .church-videos .card .card-body iframe {
    min-height: 260px !important;
    height: unset !important;
  }

  .lightbox-container {
    margin: 0px -6px !important;
    row-gap: 10px;

    img {
      width: 25% !important;
      padding: 0px 6px !important;
    }
  }
}

@media (max-width:769px) {
  .hero-slider.carousel.slide .carousel-item img {
    height: 400px !important;
  }

  footer {
    .action-btn {
      width: max-content !important;
    }
  }

  .fade.toast.show {
    position: relative !important;
  }

  .church-videos .card .card-body iframe {
    min-height: 200px !important;
  }

  .lightbox-container {
    img {
      width: 33.33% !important;
    }
  }
  .aboutImg1{
    height:380px;
  }
}

@media (max-width:576px) {
  .hero-slider.carousel.slide .carousel-item img {
    height: 200px !important;
  }

  .lightbox-container {
    img {
      width: 50% !important;
    }
  }
}